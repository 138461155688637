@import url(https://fonts.googleapis.com/css?family=Rajdhani:300,400,600&display=swap);
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Rajdhani", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #3a4a62, #1b2433);
  background-attachment: fixed;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* override webkit autofill styling */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px rgb(34, 46, 67) inset;
  -webkit-text-fill-color: #ff941a;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px rgb(34, 46, 67) inset;
  -webkit-text-fill-color: #fff;
}

input {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.Toastify__toast {
  padding: 0 0 0 1.5rem;
  border-radius: 3px;
}

.Toastify__toast-container--bottom-right {
  bottom: 0.5rem;
  right: 1rem;
  padding: 0;
}

.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}

.Toastify__toast--info {
  background: #3498db;
}

.Toastify__toast--success {
  background: #56AE56;
}

.Toastify__toast--warning {
  background: #ECA56C;
}

.Toastify__toast--error {
  background: #d47167;
}

.Toastify__toast-body {
  margin: auto 0;
  flex: 1 1;
}
/* could this be defined in the mui theme file instead? */
.MuiTypography-button {
  font-weight: 400;
}

button.MuiTab-root.Mui-selected {
  background-color: #ff941a;
}

button.MuiTab-root.Mui-selected .MuiTab-wrapper {
  color: #1b2433;
}

button.MuiTab-textColorInherit:disabled .MuiTab-wrapper {
  color: #916434;
}

.MuiTablePagination-root:last-child {
  padding: 18px 0 9px;
}

