@import url("https://fonts.googleapis.com/css?family=Rajdhani:300,400,600&display=swap");

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Rajdhani", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #3a4a62, #1b2433);
  background-attachment: fixed;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* override webkit autofill styling */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px rgb(34, 46, 67) inset;
  -webkit-text-fill-color: #ff941a;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px rgb(34, 46, 67) inset;
  -webkit-text-fill-color: #fff;
}

input {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}
