/* could this be defined in the mui theme file instead? */
.MuiTypography-button {
  font-weight: 400;
}

button.MuiTab-root.Mui-selected {
  background-color: #ff941a;
}

button.MuiTab-root.Mui-selected .MuiTab-wrapper {
  color: #1b2433;
}

button.MuiTab-textColorInherit:disabled .MuiTab-wrapper {
  color: #916434;
}

.MuiTablePagination-root:last-child {
  padding: 18px 0 9px;
}
